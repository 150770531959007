let basePath = '/api/'
switch (process.env.NODE_ENV) {
    //product
    case 'production':
        basePath = '/api/';
        break;
    //test
    default:
        basePath = 'http://staking.option.com/api/';
        break;
}
module.exports = {
    basePath
}