<template>
  <div id="app">
    <mass/>
  </div>
</template>

<script>
import mass from "./components/mass";
import { mobileRem } from "@/common/rem";
export default {
  name: 'App',
  components: {
    mass
  },
  created() {
    mobileRem()
  }
}
</script>

<style>
</style>
