import Vue from 'vue';
import Vuex from 'vuex';
import { ethers } from 'ethers';
import { message } from 'ant-design-vue';
import { getStorage, setStorage } from '@/utils';
let _provider;
if (window.ethereum) {
    _provider = new ethers.providers.Web3Provider(window.ethereum);
} else if (window.web3) {
    _provider = new ethers.providers.Web3Provider(window.web3.currentProvider);
}
const provider = _provider;
const signer = provider ? provider.getSigner() : null;
import { Network } from '@/config/constants';
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        device: 'desktop',
        account: null,
        chainId: null,
        desireChainId: '0x1',
        chainToken: null,
        currentTheme: getStorage('currentTheme') || '',
        isMobile: false,
        signer,
        provider,
    },
    getters: {
        baseSymbol(state) {
            let chain_id = state.chainId;
            if (!chain_id) {
                chain_id = '0x1';
            }
            return Network[chain_id]['nativeCurrency']['symbol'];
        },
    },
    mutations: {
        SET_ACCOUNT: (state, account) => {
            state.account = account;
        },
        SET_CHAINID: (state, id) => {
            state.chainId = id;
        },
        SET_desireChainId: (state, desireChainId) => {
            state.desireChainId = desireChainId;
        },
        SET_THEME: (state, theme) => {
            state.currentTheme = theme;
            setStorage('currentTheme', theme);
        },
        TOGGLE_DEVICE: (state, device) => {
            state.device = device;
        },
        set_ISMOBILE: (state, value) => {
            state.isMobile = value;
        },
    },
    actions: {
        updateChainId({ commit }, id) {
            if (this.state.chainId == id) {
                return;
            }
            commit('SET_CHAINID', id);
        },
        updateTheme({ commit }, theme) {
            commit('SET_THEME', theme);
        },
        updateMobile({ commit }, theme) {
            commit('set_ISMOBILE', theme);
        },
        updateAccount({ commit }, account) {
            commit('SET_ACCOUNT', account);
        },
        async connected({ commit }) {
            if (!window.ethereum) {
                message.error('install MetaMask first!');
                return;
            }
            window.ethereum.request({ method: 'eth_requestAccounts' }).then((account) => {
                account = account[0];
                commit('SET_ACCOUNT', account);
            });
        },
        async switchNetwork({ commit }, value) {
            commit('SET_desireChainId', value);
            try {
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: value }],
                });
                commit('SET_CHAINID', value);
            } catch (switchError) {
                if (switchError.code == '4001') return;
                // This error code indicates that the chain has not been added to MetaMask.
                try {
                    const item = Network[value];
                    let paramsArry = [
                        {
                            chainId: item.chainId,
                            chainName: item.chainName,
                            rpcUrls: item.rpcUrls,
                            nativeCurrency: item.nativeCurrency,
                            blockExplorerUrls: item.blockExplorerUrls ? item.blockExplorerUrls : null,
                        },
                    ];
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: paramsArry,
                    });
                    commit('SET_CHAINID', value);
                } catch (addError) {
                    console.log(addError);
                }
            }
        },
    },
});
