import { constants } from 'ethers';
const multiSender = '0x2C7fAb6832051349569Ec877d153Dc3FB3C6862c';
const MaxUint256 = constants.MaxUint256;
const zeroAddress = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee';
const Network = {
  '0x1': {
    chainId: '0x1',
    chainName: 'Ethereum Mainnet',
    rpcUrls: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
    blockExplorerUrls: ['https://etherscan.io'],
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
  },
  '0x7f08': {
    chainId: '0x7f08',
    chainName: 'Bitgert Mainnet',
    rpcUrls: ['https://rpc.icecreamswap.com'],
    blockExplorerUrls: ['https://brisescan.com'],
    nativeCurrency: {
      name: 'Bitgert',
      symbol: 'BRISE',
      decimals: 18,
    },
  },
  '0x38': {
    chainId: '0x38',
    chainName: 'BNB Smart Chain',
    rpcUrls: ['https://bsc-dataseed1.binance.org'],
    blockExplorerUrls: ['https://bscscan.com'],
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
  },
  '0xa86a': {
    chainId: '0xa86a',
    chainName: 'Avalanche C Chain',
    rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
    blockExplorerUrls: ['https://snowtrace.io'],
    nativeCurrency: {
      name: 'AVAX',
      symbol: 'AVAX',
      decimals: 18,
    },
  },
  '0x89': {
    chainId: '0x89',
    chainName: 'Polygon Chain',
    rpcUrls: ['https://polygon-rpc.com/'],
    blockExplorerUrls: ['https://polygonscan.com'],
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
  },
  '0x19': {
    chainId: '0x19',
    chainName: 'Cronos',
    rpcUrls: ['https://evm-cronos.crypto.org'],
    blockExplorerUrls: ['https://cronos.crypto.org/explorer'],
    nativeCurrency: {
      name: 'CRO',
      symbol: 'CRO',
      decimals: 18,
    },
  },
  '0xa4b1': {
    chainId: '0xa4b1',
    chainName: 'Arbitrum One',
    rpcUrls: ['https://endpoints.omniatech.io/v1/arbitrum/one/public'],
    blockExplorerUrls: ['https://arbiscan.io'],
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
  },
  '0x141': {
    chainId: '0x141',
    chainName: 'KCC Mainnet Network',
    rpcUrls: ['https://rpc-mainnet.kcc.network'],
    blockExplorerUrls: ['https://explorer.kcc.io/en'],
    nativeCurrency: {
      name: 'KCS',
      symbol: 'KCS',
      decimals: 18,
    },
  },
  '0x80': {
    chainId: '0x80',
    chainName: 'Huobi ECO Chain',
    rpcUrls: ['https://http-mainnet.hecochain.com'],
    blockExplorerUrls: ['https://hecoinfo.com'],
    nativeCurrency: {
      name: 'HT',
      symbol: 'HT',
      decimals: 18,
    },
  },
  '0x58f8': {
    chainId: '0x58f8',
    chainName: 'Map Protocol',
    rpcUrls: ['https://rpc.maplabs.io'],
    blockExplorerUrls: ['https://maposcan.io'],
    nativeCurrency: {
      name: 'MAPO',
      symbol: 'MAPO',
      decimals: 18,
    },
  },
};
export { zeroAddress, multiSender, MaxUint256, Network };
