<template>
    <div class="claim-table" :class="{ 'mobile-table': isMobile }">
      <a-table
                :columns="currentData.columns"
                :data-source="currentData.list"
                :pagination="currentData.pagination"
                @change="getEventList"
                :rowKey="
                            (record, index) => {
                              return index;
                            }
                          "
        >
            <template slot="amount" slot-scope="text">
                <div class="flex-box space-end">
                    {{text}}
                    <template v-if="currentData.status">
                      <img class="mg-l4" src="~@/assets/images/success.png">
                    </template>
                </div>
            </template>
        </a-table>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    name: 'ClaimScroll',
    props:{
        list: {
            type: Array,
            default:() => []
        },
        status:{
            type: Boolean,
            default:false
        }
    },
    data(){
        return{
            currentData: {
                name: "currentData",
                list: [],
                pagination: {
                    current: 1,
                    total: 0,
                    pageSize: 10,
                    size:"small"
                },
                columns: [
                    {
                      title:"",
                      dataIndex: "index",
                      key: "index",
                      align: 'center',
                      customRender: (text,record,index) => `${index+1}`
                    },
                    {
                        title:"Address",
                        dataIndex: "address",
                        key: "address"
                    },
                    {
                        title:"Total Amount",
                        dataIndex: "amount",
                        key: "amount",
                        align:"right",
                        scopedSlots: { customRender: "amount" },
                        width:"31%"
                    },
                ],
                status:false,
            }
        }
    },
    computed: {
        ...mapState({
            isMobile: (state) => state.isMobile,
            account: (state) => state.account,
            linkAccount:(state) => state.linkAccount,
            safeOwner:(state) => state.safeOwner,
        })
    },
    watch: {
        linkAccount: {
            immediate: true,
            handler: function (val) {
                if(val){
                    this.getEventList()
                }
            },
        },
        status(val){
            if(val){
                this.currentData.status = val
            }
        }
    },
    created(){
        this.getEventList();
    },
    methods:{
        getEventList(pagination){
            if (!pagination) {
                pagination = this.currentData.pagination;
            }
            this.currentData.list = this.list;
            this.currentData.pagination.total = this.list.length;
            this.currentData.pagination.current = pagination.current;
        }
    }
}
</script>
<style lang="scss">
    .claim-table {
      .ant-table{
        border-radius: 10px;
        border: 1px solid #037A7E;
      }

        .ant-table-pagination.ant-pagination{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            float: none;
            li{
                &.ant-pagination-item{
                    background:transparent;
                    border-color:transparent;
                    color: #8F97AA;
                    margin: 0 20px;
                }
                a{
                    &.ant-pagination-item-link{
                      color: #8F97AA;
                        .ant-pagination-item-ellipsis{
                            color: #8F97AA;
                        }
                        .ant-pagination-item-link-icon{
                            color: #8F97AA;
                        }
                    }
                    color: #8F97AA;
                }
                &:hover{
                    border-color:transparent;
                    a{
                        &.ant-pagination-item-link{
                            border-color:transparent;
                            color: #00C5BD;
                        }
                        color: #00C5BD;
                    }
                }
                &.ant-pagination-item-active{
                    border-color:transparent;
                    background: rgba(0,197,189,0.3000);
                    border-radius: 50%;
                    min-width: 18px;
                    height: 18px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    a{
                      color:#ffffff ;
                      padding: 0;
                    }
                }
            }
        }
        .ant-table-wrapper {
            background: transparent;
        }
        .ant-table-thead {
            th {
                padding:12px 22px 6px;
                background:transparent;
                border: none;
                border-radius: 0 !important;
                color: rgba(255,255,255,0.3000);
                font-size: 12px;
                &:first-child {
                  border-right: 1px solid rgba(3,122,126,0.3000);
                }
            }
        }
        .ant-table-tbody{
        tr {
          td {
            padding:6px 22px;
            border-bottom:none !important;
            color: #00C5BD;
            font-size: 14px;
            background:transparent !important;
            &:first-child {
              border-right: 1px solid rgba(3,122,126,0.3000);
            }
          }
          &:last-child{
            td{
              padding-bottom: 12px;
            }
          }
        }
      }

        .ant-table-placeholder {
            border: none !important;
            color: #e4e4e4 !important;
        }
        .ant-empty-description {
            color: #e4e4e4 !important;
        }
        .claim-date{
            padding-left: 20px;
        }
        .claim-circle{
            width: 32px;
            height: 32px;
            background: #FFFFFF;
            border: 1px solid #3DD598;
            box-shadow: 0px 10px 20px rgba(61, 213, 152, 0.15);
            border-radius: 50%;
            position: relative;
            flex: none;
            &:before{
                content: "";
                position: absolute;
                left:5px;
                top:5px;
                width: 20px;
                height: 20px;
                background: #3DD598;
                border: 1px solid #3DD598;
                border-radius: 50%;
            }
            &.bg-FFC94D{
                border: 1px solid #FFC94D;
                &:before{
                    background: #FFC94D;
                    border: 1px solid #FFC94D;
                }
            }
        }
        .claim-amount{
            font-size: 16px;
            color: #727272;
            justify-content: flex-end;
            span{
                width: 80px;
                height: 30px;
                line-height: 30px;
                background: #111111;
                border-radius: 4px;
                font-size: 14px;
                font-family: AvertaStd-Regular;
                letter-spacing: 2px;
                color:#ffffff;
                text-align: center;
                margin-left: 20px;
            }
        }
    }
    .mobile-table{
      .ant-table{
        border-radius: 0.2rem;
      }
      .ant-table-pagination.ant-pagination{
        margin:0.28rem 0;
        li{
          &.ant-pagination-item{
            margin: 0 0.32rem;
            font-size: 0.24rem;
          }
          &.ant-pagination-item-active{
            min-width: 0.36rem;
            height: 0.36rem;
          }
        }
      }
      .ant-table-thead {
        th {
          padding:0.24rem 0.16rem 0.04rem;
          font-size: 0.24rem;
        }
      }
      .ant-table-tbody{
        tr {
          td {
            padding:0.1rem 0.16rem;
            font-size: 0.28rem;
            word-break: break-all;
          }
          &:last-child{
            td{
              padding-bottom: 0.24rem;
            }
          }
        }
      }
    }
</style>
