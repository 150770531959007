import Vue from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue';
import VueBus from 'vue-bus'
import store from './store'
import * as filters from '@/filter/index';
import 'ant-design-vue/dist/antd.css';
import "./assets/css/commons.scss";
import './assets/css/index.scss';

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
Vue.config.productionTip = false
Vue.use(Antd)
Vue.use(VueBus);

new Vue({
  store,
  render: h => h(App),
}).$mount('#app')
