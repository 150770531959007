import BigNumber from 'bignumber.js';
import moment from 'moment';
BigNumber.set({ ROUNDING_MODE: BigNumber.ROUND_DOWN });
const isNullOrEmpty = function(val) {
    if (val == null || val == '' || typeof val == undefined) {
        return true;
    } else {
        return false;
    }
};

const timeFormat = (value, format) => {
    if (/^\d+$/.test(value)) {
        return moment
            .unix(value)
            .utc()
            .format(format);
    } else {
        return moment
            .utc(value)
            .utc()
            .format(format);
    }
};

const decimals = function(val, size = 4) {
    if (!val) {
        return val === 0 ? '0.000' : '-';
    }
    return BigNumber(val.toString()).toFixed(size);
};
const decimalsPrecision = function(val, precision, decimals = 4) {
    if (!val) {
        return '0.' + '0'.repeat(decimals);
    }
    let num = BigNumber(val.toString())
    .div(BigNumber(10).pow(precision))
    .toFixed(decimals);
    return num;
};
const decimalsUnit = function(val, size = 3) {
    if (!val) {
        return val === 0 ? '0.000' : '-';
    }
    let unit = '';
    if (val >= 1e3 && val < 1e6) {
        val = val / 1e3;
        unit = 'K';
        size = 1;
    }
    if (val >= 1e6 && val < 1e9) {
        val = val / 1e6;
        unit = 'M';
        size = 1;
    }
    if (val >= 1e9) {
        val = val / 1e9;
        unit = 'B';
        size = 1;
    }
    return new BigNumber(val.toString()).toFixed(size) + unit;
};
export { isNullOrEmpty, timeFormat, decimals, decimalsUnit, decimalsPrecision };
